import logo from "../../../assets/logo-benefit.svg";
import React from "react";
import { LanguageSwitcher } from "../language-switcher";
import "./header.css";

export const Header: React.FunctionComponent = () => {
  return (
    <header className="header">
      <div className="header__wrapper">
        <img src={logo} className="header__logo" />
        <LanguageSwitcher />
      </div>
    </header>
  );
};
