import keycloak, { keycloakInitOptions } from "./bootstrap/keycloak/keycloak";
import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { App } from "./App";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import "./index.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Suspense fallback={<div />}>
    <ReactKeycloakProvider
      initOptions={keycloakInitOptions}
      authClient={keycloak}
    >
      <App />
    </ReactKeycloakProvider>
  </Suspense>
);

