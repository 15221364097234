import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import keycloak from "../../../bootstrap/keycloak/keycloak";
import {
  CardInfo,
  EmployeeTransactionsIds,
  LeadingMethodDto,
  Order,
  OrderResponse,
  OrderStatusResponse,
  PagedContent,
  RecurringChangeResponse,
  RecurringChangeStatusDTO,
  RecurringInfoDTO,
  RecurringPayload,
} from "./payments.types";

const DEFAULT_PAGE_SIZE = 3;

const DEV_API_URL =
  "https://payments-gateway.dv1-priv.dev.aws.mybenefit.pl" as const;

const baseUrl = window.location.host.includes("localhost")
  ? `${DEV_API_URL}/api/rest`
  : `/api/payments-gateway/rest`;

const paymentApiDefinition = () => {
  const config = {
    get baseConfig(): AxiosRequestConfig {
      return {
        headers: {
          Authorization: `Bearer ${keycloak.token}`,
        },
      };
    },
  };

  const createOrder = async (order: Order): Promise<OrderResponse> => {
    const response = await axios.post<
      OrderResponse,
      AxiosResponse<OrderResponse>,
      Order
    >(`${baseUrl}/v1/orders`, order, {
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
      },
    });
    return response.data;
  };

  const getOrderStatus = async (
    orderId: string
  ): Promise<OrderStatusResponse> => {
    const response = await axios.get<OrderStatusResponse>(
      `${baseUrl}/v1/employee-orders/${orderId}`,
      config.baseConfig
    );
    return response.data;
  };

  const getLeadingMethod = async (
    transactionsIds: EmployeeTransactionsIds
  ): Promise<LeadingMethodDto> => {
    const response = await axios.post<
      LeadingMethodDto,
      AxiosResponse<LeadingMethodDto>,
      EmployeeTransactionsIds
    >(
      `${baseUrl}/v2/employee-transactions/leading-method`,
      transactionsIds,
      config.baseConfig
    );
    return response.data;
  };

  const getRecurring = async (recurringId: string) => {
    const response = await axios.get<RecurringInfoDTO>(
      `${baseUrl}/v1/employee-recurrings/${recurringId}`,
      config.baseConfig
    );
    return response.data;
  };

  const getEmployeeCards = async (payload: {
    filters?: string;
    page?: number;
    size?: number;
  }) => {
    const response = await axios.get<PagedContent<CardInfo>>(
      `${baseUrl}/v1/employee-cards`,
      {
        ...config.baseConfig,
        params: { ...payload, size: payload.size || DEFAULT_PAGE_SIZE },
      }
    );
    return response.data;
  };

  const patchRecurring = async (
    recurringId: string,
    payload: RecurringPayload
  ) => {
    const response = await axios.patch<
      RecurringChangeResponse,
      AxiosResponse<RecurringChangeResponse>,
      RecurringPayload
    >(
      `${baseUrl}/v1/employee-recurrings/${recurringId}`,
      payload,
      config.baseConfig
    );
    return response;
  };

  const getRecurringStatus = async (authOrderId: string) => {
    const response = await axios.get<RecurringChangeStatusDTO>(
      `${baseUrl}/v1/auth-orders/${authOrderId}`,
      config.baseConfig
    );
    return response.data;
  };

  return {
    createOrder,
    getOrderStatus,
    getLeadingMethod,
    getRecurring,
    getEmployeeCards,
    patchRecurring,
    getRecurringStatus,
  };
};

export const paymentApi = paymentApiDefinition();
