import CardFooter from "../../utils/card/card-footer";
import cardsLogo from "../../../assets/cards.png";
import cardsLogoBig from "../../../assets/cards@2x.png";
import infoIcon from "../../../assets/info.svg";
import lockIcon from "../../../assets/lock-closed.svg";
import PaymentDetails from "../payment-details/payment-details";
import payULogo from "../../../assets/payu.png";
import payULogoBig from "../../../assets/payu@2x.png";
import React, { useState } from "react";
import { PaymentMethod } from "../../../services/api/payments/payments.types";
import { TransactionsSummary } from "../../../utils/get-transactions-summary";
import { useTranslation } from "react-i18next";
import "./hybrid-payment.css";

export interface HybridPaymentProps {
  setSelectedPaymentMethod: React.Dispatch<React.SetStateAction<PaymentMethod>>;
  transactionsSummary: TransactionsSummary;
  payByLink: () => Promise<void>;
}

const HybridPayment: React.FC<HybridPaymentProps> = ({
  transactionsSummary,
  setSelectedPaymentMethod,
  payByLink,
}) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);

  const handleCyclicPayment = () => {
    setSelectedPaymentMethod("CYCLIC");
  };

  const handleSinglePayment = () => {
    setLoading(true);

    triggerSinglePayment();
  };

  const triggerSinglePayment = async () => {
    try {
      await payByLink();
    } catch (e) {
      //TODO: Add error handler
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <PaymentDetails transactionsSummary={transactionsSummary} />
      <section className="container card hybrid-payment">
        <h1 className="hybrid-payment__title">
          {t("Wybierz metodę płatności")}
        </h1>
        <div className="hybrid-payment__info">
          <img
            className="hybrid-payment__info__icon"
            src={infoIcon}
            alt="info icon"
          />
          {t(
            "Wybrana metoda będzie domyślna podczas kolejnego okres rozliczeniowego"
          )}
        </div>

        <button
          data-testid="hybridPaymentPayByCardButton"
          onClick={handleCyclicPayment}
          disabled={loading}
          className="card__item card__item--button"
        >
          <img
            className="card__item__icon"
            width="83"
            height="35"
            srcSet={`${cardsLogoBig} 2x`}
            src={cardsLogo}
            alt="cards logo"
          />
          <div className="card__item__text">
            <h2 className="card__item__title">
              {t("Płatność cykliczna kartą")}
              <img width="18" height="18" src={lockIcon} />
            </h2>
            <span className="card__item__desc">
              {t("Każdego miesiąca będziemy potrącali opłatę z Twojej karty")}
            </span>
          </div>
        </button>

        <button
          data-testid="hybridPaymentPayByLinkButton"
          onClick={handleSinglePayment}
          disabled={loading}
          className="card__item card__item--button"
        >
          <img
            className="card__item__icon"
            width="83"
            height="35"
            srcSet={`${payULogoBig} 2x`}
            src={payULogo}
            alt="PayU logo"
          />
          <div className="card__item__text">
            <h2 className="card__item__title">{t("Płatność jednorazowa")}</h2>
            <span className="card__item__desc">
              {t("Przejdź do PayU i wybierz opcję płatności jednorazowej")}
            </span>
          </div>
        </button>

        <CardFooter />
      </section>
    </>
  );
};

export default HybridPayment;
