import CardFooter from "../../utils/card/card-footer";
import cardsLogo from "../../../assets/cards.png";
import cardsLogoBig from "../../../assets/cards@2x.png";
import infoIcon from "../../../assets/info.svg";
import Loading from "../../utils/loading";
import lockIcon from "../../../assets/lock-closed.svg";
import payULogo from "../../../assets/payu.png";
import payULogoBig from "../../../assets/payu@2x.png";
import React, { useState } from "react";
import { ArrowLeft } from "@benefit-systems/common-components";
import { getStatusHTML } from "./current-recurring.helpers";
import { isUUIDValid } from "../../../utils/isValidUUID";
import { paymentApi } from "../../../services/api/payments/payments.api";
import { useParams, useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import "./current-recurring.css";
import StatusCard, {
  FALLBACK_URI,
  REDIRECT_URI,
  StatusCardEnum,
} from "../../utils/status-card";
import {
  ErrorResponse,
  RecurringInfoDTO,
  RecurringPaymentByCardInfo,
} from "../../../services/api/payments/payments.types";

export interface CurrentRecurringProps {
  nextPage: () => void;
}

export const CurrentRecurring: React.FC<CurrentRecurringProps> = ({
  nextPage,
}) => {
  const { t } = useTranslation();
  const { recurringId } = useParams();
  const isRecurringIdValid = isUUIDValid(recurringId);
  const [params] = useSearchParams();
  const [isCustomError, setIsCustomError] = useState<boolean>(false);

  const fallback: string =
    params.get(FALLBACK_URI) ||
    params.get(REDIRECT_URI) ||
    process.env.REACT_APP_RESERVE_FALLBACK_LINK;

  const {
    data,
    isError: isResponseError,
    isLoading,
  } = useQuery<RecurringInfoDTO, ErrorResponse>({
    enabled: isRecurringIdValid,
    queryKey: ["getRecurring"],
    queryFn: () => paymentApi.getRecurring(recurringId),
    retry: false,
    onError: () => {
      setIsCustomError(true);
    },
  });

  if (!isRecurringIdValid) {
    const messageContent: string[] = [
      t("Ups! Podany parametr nie jest poprawny."),
    ];
    const messageToShow: string = messageContent.join("<br />");
    return <StatusCard message={messageToShow} type={StatusCardEnum.ERROR} />;
  }

  if (isResponseError || isCustomError) {
    const messageContent: string[] = [
      t("Ups! Pojawił się błąd podczas weryfikowania obecnej metody płatności"),
      t("Odśwież stronę i spróbuj ponownie."),
    ];
    const messageToShow: string = messageContent.join("<br />");
    return <StatusCard message={messageToShow} type={StatusCardEnum.ERROR} />;
  }

  const isCardPayment: boolean = data?.type === "PAY_BY_CARD";

  if (data?.status === "PENDING") {
    const messageContent: string[] = [
      t("Twoja płatność jest aktualnie w trakcie przetwarzania."),
    ];
    const messageToShow: string = messageContent.join("<br />");
    return <StatusCard message={messageToShow} type={StatusCardEnum.INFO} />;
  }

  if (data?.status === "DEACTIVATED") {
    const messageContent: string[] = [
      t("Twoja płatność została dezaktywowana."),
    ];
    const messageToShow: string = messageContent.join("<br />");
    return <StatusCard message={messageToShow} type={StatusCardEnum.ERROR} />;
  }

  if (isLoading || !data) {
    return <Loading />;
  }

  return (
    <section className="container card current-recurring">
      <button
        data-testid="changeRecurringBackButton"
        className="current-recurring__back-button"
        onClick={() => {
          location.href = fallback;
        }}
      >
        <ArrowLeft width={10} height={10} /> {t("Wróć")}
      </button>

      <h1 className="current-recurring__title">
        {t("Zarządzaj wybraną metodą płatności")}
      </h1>

      <div className="current-recurring__info">
        <img
          className="current-recurring__info__icon"
          src={infoIcon}
          alt="info icon"
        />
        {t(
          "Wybrana metoda jest domyślną podczas kolejnego okresu rozliczeniowego"
        )}
      </div>

      <span>{t("Aktualna metoda płatności")}:</span>
      <div className="current-recurring__method">
        <div className="current-recurring__payment-info">
          <img
            className="card__item__icon"
            width="83"
            height="35"
            srcSet={`${isCardPayment ? cardsLogoBig : payULogoBig} 2x`}
            src={isCardPayment ? cardsLogo : payULogo}
            alt={isCardPayment ? "cards logo" : "PayU logo"}
          />
          <div className="card__item__text">
            <h2 className="card__item__title">
              {isCardPayment ? (
                <>
                  {t("Płatność cykliczna kartą")}
                  <img width="18" height="18" src={lockIcon} />
                </>
              ) : (
                t("Płatność jednorazowa")
              )}
            </h2>
            <span className="card__item__desc">
              {t(
                isCardPayment
                  ? "Każdego miesiąca będziemy potrącali opłatę z Twojej karty"
                  : "Wybrano płatność przez PayU - płatność jednorazową"
              )}
            </span>
          </div>
        </div>
        <hr className="current-recurring__separator" />
        <div className="current-recurring__payment-details">
          <div>
            <span>{t("Produkt")}:</span>
            <div>
              <span>{data.name}</span>
            </div>
          </div>
          <div>
            <span>{t("Status")}:</span>
            <>{data && getStatusHTML(data.status, t)}</>
          </div>
          {isCardPayment && (data as RecurringPaymentByCardInfo)?.employeeCard && (
            <div>
              <span>{t("Karta")}:</span>
              <div className="current-recurring__card-details">
                <span>
                  {(data as RecurringPaymentByCardInfo).employeeCard.number}
                </span>
                <span>
                  {
                    (data as RecurringPaymentByCardInfo).employeeCard
                      .expirationMonth
                  }
                  /
                  {
                    (data as RecurringPaymentByCardInfo).employeeCard
                      .expirationYear
                  }
                </span>
              </div>
            </div>
          )}
        </div>
        <button
          className="current-recurring__edit-button"
          onClick={nextPage}
          data-testid="changeRecurringSubmitButton"
        >
          {t(
            isCardPayment ? "Edytuj kartę" : "Zmień na płatność cykliczną kartą"
          )}
        </button>
      </div>
      <CardFooter />
    </section>
  );
};
