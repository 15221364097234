import { AuthOrderParamsInfo } from "../views/finalize-recurring/finalize-recurring.types";
import { validate } from "uuid";

export const getAuthOrderFromParams = (
  params: URLSearchParams
): AuthOrderParamsInfo => {
  const authOrderId: string = params.get("authOrderId");

  const isAuthOrderIdValid: boolean = validate(authOrderId);

  let errors: Record<string, string> = {};

  if (!isAuthOrderIdValid) {
    errors.authOrderIdMissed = "Search param AuthOrderId is required";
  }

  return {
    params: {
      authOrderId,
    },
    isParamsValid: isAuthOrderIdValid,
    errors,
  };
};
