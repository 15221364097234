import React, { ReactNode, useEffect, useState } from "react";
import { AppMode } from "../components/utils/card-form/card-form";
import { getAppMode } from "../components/utils/main/main.helpers";

export interface AppContext {
  mode: AppMode | null;
}

export const AppContext = React.createContext<AppContext>({
  mode: null,
});

export function AppContextProvider({
  children,
}: {
  children: ReactNode | ReactNode[];
}) {
  const [mode, setMode] = useState<AppMode | null>(null);

  useEffect(() => {
    const appMode = getAppMode();
    if (appMode !== mode) {
      setMode(appMode);
    }
  }, [location, setMode, mode]);

  return (
    <AppContext.Provider
      value={{
        mode,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}
