import CardFooter from "../card/card-footer";
import CardForm, { AppMode } from "../card-form/card-form";
import CardSelect from "../card-select/card-select";
import cardsLogo from "../../../assets/cards.png";
import cardsLogoBig from "../../../assets/cards@2x.png";
import lockIcon from "../../../assets/lock-closed.svg";
import React from "react";
import { ArrowLeft } from "@benefit-systems/common-components";
import { PaymentMethod } from "../../../services/api/payments/payments.types";
import { useTranslation } from "react-i18next";
import "./card-section.css";

export type PayPayload = { payuToken: string } | { employeeCardId: string };

export interface CardSectionProps {
  totalAmount: number;
  isLoading: boolean;
  onPay: (payload: PayPayload) => Promise<void>;
  setSelectedPaymentMethod?: React.Dispatch<
    React.SetStateAction<PaymentMethod>
  >;
}

const CardSection: React.FC<CardSectionProps> = ({
  totalAmount,
  isLoading,
  onPay,
  setSelectedPaymentMethod,
}) => {
  const { t } = useTranslation();

  // If setSelectedPaymentMethod exist, user can back to choose payment method view
  const isPaymentConfigurationHybrid: boolean = Boolean(
    setSelectedPaymentMethod
  );

  const handleChangePaymentMethod = () => {
    setSelectedPaymentMethod?.("HYBRID");
  };

  const mode = AppMode.TRANSACTION;

  return (
    <section className="container card">
      {isPaymentConfigurationHybrid && (
        <button
          className="card-back-button"
          onClick={handleChangePaymentMethod}
        >
          <ArrowLeft width={12} height={12} />{" "}
          {t("Wybierz inną metodę płatności")}
        </button>
      )}
      <div className="container card card-section">
        <div className="card__item">
          <img
            className="card__item__icon"
            width="83"
            height="35"
            srcSet={`${cardsLogoBig} 2x`}
            src={cardsLogo}
            alt="cards logo"
          />
          <div className="card__item__text">
            <h2 className="card__item__title">
              {t("Płatność cykliczna kartą")}
              <img width="18" height="18" src={lockIcon} />
            </h2>
            <span className="card__item__desc">
              {t("Każdego miesiąca będziemy potrącali opłatę z Twojej karty")}
            </span>
          </div>
        </div>

        <hr className="card-section__separator" />
        <CardSelect
          onCardCheck={(employeeCardId) => onPay({ employeeCardId })}
          isLoading={isLoading}
        />
        <p className="card-section__text">{t("lub wprowadź nową kartę")}:</p>
        <CardForm
          totalAmount={totalAmount}
          onFinalize={(payuToken) => onPay({ payuToken })}
        />
      </div>

      <CardFooter />
    </section>
  );
};

export default CardSection;
