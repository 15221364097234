import React, { ErrorInfo } from "react";

interface ErrorBoundaryProps extends React.PropsWithChildren {
  hasError?: boolean;
}

type ErrorBoundaryState = {
  hasError: boolean;
  error: Error | undefined;
};

type ErrorLabels = {
  pl: string;
  en: string;
};

export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: this.props.hasError || false,
      // eslint-disable-next-line no-undefined
      error: undefined,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error) {
    this.setState({ error });
  }

  render() {
    const { hasError, error } = this.state;
    const { children } = this.props;

    if (!hasError) {
      return children;
    }

    const defaultErrorInfo: ErrorLabels = {
      pl: "Coś poszło nie tak. Spróbuj odświeżyć stronę.",
      en: "Something went wrong, try refreshing the page.",
    };

    let errorInfo: ErrorLabels | undefined;

    return (
      <div
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <p style={{ fontWeight: "bolder", fontSize: "1.2rem" }}>
          {errorInfo?.pl || defaultErrorInfo.pl}
        </p>
        <p style={{ fontWeight: "bolder", fontSize: "1rem" }}>
          {errorInfo?.en || defaultErrorInfo.en}
        </p>
      </div>
    );
  }
}
