import React, { useMemo } from "react";
import StatusCard, { StatusCardEnum } from "../../utils/status-card";
import TransactionsView from "../../../views/transactions/transactions";
import { AppMode } from "../../utils/card-form/card-form";
import { getPaymentConfigFromParams } from "../../../utils/get-payment-config-from-params";
import { PaymentConfigParamsInfo } from "../../../views/transactions/transactions.types";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const TransactionsParamsAnalyzer: React.FC = () => {
  const [params] = useSearchParams();
  const { t } = useTranslation();

  const { params: paymentConfig, isParamsValid } =
    useMemo<PaymentConfigParamsInfo>(
      () => getPaymentConfigFromParams(params),
      [params]
    );

  if (!isParamsValid) {
    return (
      <StatusCard
        message={t("Podane parametry transakcji są nieprawidłowe.")}
        type={StatusCardEnum.ERROR}
      />
    );
  }

  return <TransactionsView paymentConfig={paymentConfig} />;
};

export default TransactionsParamsAnalyzer;
