import { useExternalScript } from "../../../hooks/useExternalScript/useExternalScript";

interface PayUWrapperProps {
  children: React.ReactElement;
}

const PayUWrapper: React.FC<PayUWrapperProps> = ({ children }) => {
  const state = useExternalScript(process.env.REACT_APP_PAYU_SDK_LINK);

  return state === "ready" && children;
};

export default PayUWrapper;
