import CardFooter from "../card/card-footer";
import React, { useEffect, useState } from "react";
import { getStatusIcon } from "./status-card.helpers";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./status-card.css";

export const FALLBACK_URI = "fallbackRedirectUri";
export const REDIRECT_URI = "redirectUri";

export enum StatusCardEnum {
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
  INFO = "INFO",
}

interface StatusCardProps {
  message: string;
  type: StatusCardEnum;
  withFallback?: boolean;
  withAutoFallback?: boolean;
  hasFooter?: boolean;
  onFallback?: () => void;
}
const StatusCard: React.FC<StatusCardProps> = ({
  message,
  type,
  onFallback,
  withFallback = true,
  withAutoFallback = false,
  hasFooter = true,
}) => {
  const { t } = useTranslation();
  const [params] = useSearchParams();
  const [secCount, setSecCount] = useState(
    +process.env.REACT_APP_SUCCESS_FALLBACK_SECONDS
  );

  const fallback: string =
    params.get(FALLBACK_URI) ||
    params.get(REDIRECT_URI) ||
    process.env.REACT_APP_RESERVE_FALLBACK_LINK;

  useEffect(() => {
    if (!withAutoFallback) {
      return;
    }

    if (secCount <= 1) {
      location.href = fallback;
    }
    const timer = setTimeout(() => setSecCount((prev) => prev - 1), 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [withAutoFallback, secCount, setSecCount, fallback]);

  const fallbackFunction: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault();
    if (onFallback) {
      onFallback();
    } else {
      location.href = fallback;
    }
  };
  const StatusIcon: JSX.Element = getStatusIcon(type);

  return (
    <div className="container card">
      <div className="status-card">
        <div className="status-card__icon">{StatusIcon}</div>
        <span
          className="status-card__message"
          dangerouslySetInnerHTML={{ __html: message }}
        />
        {withAutoFallback && (
          <span>
            {t("Zostaniesz przekierowany na stronę powrotną za")} {secCount}{" "}
            {t("sekund")}
          </span>
        )}
        {withFallback && (
          <a
            data-testid="cardFallbackButton"
            href={onFallback ? undefined : fallback}
            onClick={fallbackFunction}
            className="status-card__fallback"
          >
            {t("Wróć do Dashboardu")}
          </a>
        )}
      </div>
      {hasFooter && <CardFooter />}
    </div>
  );
};

export default StatusCard;
