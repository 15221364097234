import CyclicPayments from "../../components/payments/cyclic-payment/cyclic-payment";
import HybridPayment from "../../components/payments/hybrid-payment/hybrid-payment";
import Loading from "../../components/utils/loading";
import PayUWrapper from "../../components/utils/payu-wrapper/payu-wrapper";
import React, { useEffect, useMemo, useState } from "react";
import StatusCard, { StatusCardEnum } from "../../components/utils/status-card";
import { AppMode } from "../../components/utils/card-form/card-form";
import { createPayByLinkOrder } from "../../utils/create-order";
import { paymentApi } from "../../services/api/payments/payments.api";
import { PaymentConfigParams } from "./transactions.types";
import { PayPayload } from "../../components/utils/card-section/card-section";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useRequestPolling } from "../../hooks/useRequestPolling/useRequestPolling";
import { useTranslation } from "react-i18next";
import {
  EmployeeTransactionsIds,
  ErrorResponse,
  LeadingMethod,
  LeadingMethodDto,
  Order,
  OrderByCardWithPayU,
  OrderBySavedCard,
  OrderResponse,
  PaymentMethod,
} from "../../services/api/payments/payments.types";
import {
  getTransactionsSummary,
  TransactionsSummary,
} from "../../utils/get-transactions-summary";

const MAX_RETRY_TO_GET_LEADING_METHOD =
  Number(process.env.REACT_APP_MAX_RETRY_TO_GET_LEADING_METHOD) || 10;
const REFETCH_INTERVAL_TO_GET_LEADING_METHOD =
  Number(process.env.REACT_APP_REFETCH_INTERVAL_TO_GET_LEADING_METHOD) || 1000;

export interface TransactionsView {
  paymentConfig: PaymentConfigParams;
}

const TransactionsView: React.FC<TransactionsView> = ({ paymentConfig }) => {
  const { t } = useTranslation();
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<PaymentMethod | null>(null);
  const [isCustomResponseError, setIsCustomResponseError] =
    useState<boolean>(false);

  const {
    data,
    isError: isResponseError,
    refetch,
  } = useQuery<LeadingMethodDto, ErrorResponse>({
    retry: 1,
    enabled: false,
    queryKey: ["getLeadingMethod"],
    queryFn: () => {
      const { transactions } = paymentConfig;
      const employeeTransactionsIds: EmployeeTransactionsIds = { transactions };
      return paymentApi.getLeadingMethod(employeeTransactionsIds);
    },
    onSuccess(data) {
      const { isReadyToPay, leadingMethod, redirectUri } = data;

      if (leadingMethod === "REDIRECT" && redirectUri) {
        location.href = redirectUri;
        return;
      }

      if (!isReadyToPay && leadingMethod !== "INCOMPATIBLE") {
        retry();
      }
    },
  });

  const {
    mutate,
    isLoading,
    isError: isOrderError,
  } = useMutation<OrderResponse, void, Order>({
    mutationFn: (payload) => paymentApi.createOrder(payload),
    onSuccess: (data) => {
      // Redirect
      if (data?.redirectUri) {
        location.href = data.redirectUri;
        return;
      } else {
        location.href = process.env.REACT_APP_RESERVE_FALLBACK_LINK;
      }
    },
  });

  const { isMaxNumberOfRefetch, retry } = useRequestPolling(refetch, {
    MAX_RETRY: MAX_RETRY_TO_GET_LEADING_METHOD,
    REFETCH_INTERVAL: REFETCH_INTERVAL_TO_GET_LEADING_METHOD,
  });

  const transactionSummary = useMemo<TransactionsSummary>(
    () => getTransactionsSummary(data?.transactions || []),
    [data]
  );

  const isReadyToPay: boolean = Boolean(data?.isReadyToPay);
  const leadingMethod: LeadingMethod | null = data?.leadingMethod || null;
  const { transactionsIds } = transactionSummary;
  const { origin } = paymentConfig;

  // Automatically pay by link
  useEffect(() => {
    (async () => {
      const isSinglePaymentMethod: boolean =
        isReadyToPay && leadingMethod === "SINGLE";
      if (isSinglePaymentMethod) {
        await payByLink();
      }
    })();
  }, [data, transactionSummary, leadingMethod]);

  useEffect(() => {
    const paymentMethodKeys: PaymentMethod[] = ["SINGLE", "CYCLIC", "HYBRID"];

    if (leadingMethod && paymentMethodKeys.includes(leadingMethod as any)) {
      setSelectedPaymentMethod(leadingMethod as PaymentMethod);
    }
  }, [data]);

  const payByLink = async () => {
    try {
      const orderResponse: OrderResponse = await createPayByLinkOrder(
        transactionsIds,
        origin
      );
      location.href = orderResponse.redirectUri;
    } catch (error) {
      setIsCustomResponseError(true);
    }
  };

  const onPay = async (payload: PayPayload) => {
    const orderPart: Partial<OrderByCardWithPayU> | Partial<OrderBySavedCard> =
      {
        transactions: transactionsIds,
        type: "PAY_BY_CARD",
        origin,
      };

    if ("payuToken" in payload) {
      (orderPart as OrderByCardWithPayU).token = payload.payuToken;
    }

    if ("employeeCardId" in payload) {
      (orderPart as OrderBySavedCard).employeeCardId = payload.employeeCardId;
    }

    const order: Order = orderPart as Order;

    mutate(order);
  };

  const paymentMethod = useMemo((): JSX.Element => {
    // If isPaymentConfigurationHybrid flag have value true, user can change payment method
    // Eg using back button in card view
    const isPaymentConfigurationHybrid: boolean = leadingMethod === "HYBRID";
    switch (selectedPaymentMethod) {
      case "CYCLIC":
        return (
          <CyclicPayments
            isLoading={isLoading}
            transactionsSummary={transactionSummary}
            onPay={onPay}
            setSelectedPaymentMethod={
              isPaymentConfigurationHybrid
                ? setSelectedPaymentMethod
                : undefined
            }
          />
        );
      case "HYBRID":
        return (
          <HybridPayment
            transactionsSummary={transactionSummary}
            setSelectedPaymentMethod={setSelectedPaymentMethod}
            payByLink={payByLink}
          />
        );
      case "SINGLE":
      default:
        return <></>;
    }
  }, [
    transactionSummary,
    leadingMethod,
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    payByLink,
    isLoading,
    onPay,
  ]);

  if (leadingMethod === "BLOCKED") {
    const messageContent: string[] = [
      t("Płatność chwilowo niedostępna."),
      `${t("Nie możemy obecnie przetworzyć Twojej płatności.")} ${t(
        "Wróć na strone główna, aby sprawdzić komunikat na górze strony - informuje on, w które dni płatności są wstrzymane."
      )} ${t("Opłać swoją karte po tym terminie.")}`,
    ];
    const messageToShow: string = messageContent.join("<br />");
    return <StatusCard message={messageToShow} type={StatusCardEnum.ERROR} />;
  }

  if (leadingMethod === "COMPLETED") {
    const messageContent: string[] = [
      t("Twoja transakcja została już opłacona."),
    ];
    const messageToShow: string = messageContent.join("<br />");
    return <StatusCard message={messageToShow} type={StatusCardEnum.INFO} />;
  }

  if (leadingMethod === "INCOMPATIBLE") {
    const messageContent: string[] = [
      t("Przepraszamy, ale nie mogliśmy sfinalizować Twojej transakcji."),
      t("Podane transakcje nie mogą być opłacane wspólnie."),
    ];
    const messageToShow: string = messageContent.join("<br />");
    return <StatusCard message={messageToShow} type={StatusCardEnum.ERROR} />;
  }

  if (isOrderError) {
    const messageContent: string[] = [
      t("Ups! Coś poszło nie tak."),
      t("Spróbuj ponownie później."),
    ];
    const messageToShow: string = messageContent.join("<br />");
    return <StatusCard message={messageToShow} type={StatusCardEnum.ERROR} />;
  }

  if (isResponseError || isCustomResponseError) {
    const messageContent: string[] = [
      t("Ups! Coś poszło nie tak."),
      t("Sprawdź parametry transakcji i spróbuj ponownie."),
    ];
    const messageToShow: string = messageContent.join("<br />");
    return <StatusCard message={messageToShow} type={StatusCardEnum.ERROR} />;
  }

  if (isMaxNumberOfRefetch) {
    const messageContent: string[] = [
      t(
        "Ups! Nie jesteśmy wstanie obecnie zweryfikować statusu Twoich transakcji."
      ),
      t("Odśwież stronę i spróbuj ponownie."),
    ];
    const maxNumberOfRefetchError: string = messageContent.join("<br />");
    return (
      <StatusCard
        message={maxNumberOfRefetchError}
        type={StatusCardEnum.ERROR}
      />
    );
  }

  if (!isReadyToPay) {
    return <Loading />;
  }

  return <PayUWrapper>{paymentMethod}</PayUWrapper>;
};

export default TransactionsView;
