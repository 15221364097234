import React from "react";
import { RecurringStatus } from "../../../services/api/payments/payments.types";
import { TFunction } from "react-i18next";

export const statusMapper = new Map<RecurringStatus, [string, string]>([
  ["NEW", ["Nowy", "#FFDE21"]],
  ["PENDING", ["Oczekujący", "#FFDE21"]],
  ["READY_TO_USE", ["Aktywny", "#1B5E20"]],
  ["DEACTIVATED", ["Nieaktywny", "#FF2C2C"]],
]);

export const getStatusHTML = (
  status: RecurringStatus,
  t: TFunction
): JSX.Element => {
  if (!status) {
    return <></>;
  }
  const [label, color] = statusMapper.get(status);
  return <span style={{ color }}>{t(label)}</span>;
};
