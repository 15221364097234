import {
  Order,
  OrderResponse,
  Origin,
} from "../services/api/payments/payments.types";
import { paymentApi } from "../services/api/payments/payments.api";

export const createPayByLinkOrder = async (
  transactionsIds: string[],
  origin: Origin
): Promise<OrderResponse> => {
  const order: Order = {
    transactions: transactionsIds,
    type: "PAY_BY_LINK",
    origin,
  };
  return paymentApi.createOrder(order);
};
