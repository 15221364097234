import { OrderParamsInfo } from "../views/finalize-order/finalize-order.types";
import { validate } from "uuid";

export const getOrderFromParams = (
  params: URLSearchParams
): OrderParamsInfo => {
  const orderId: string = params.get("orderId");

  const isOrderIdValid: boolean = validate(orderId);

  let errors: Record<string, string> = {};

  if (!isOrderIdValid) {
    errors.orderIdMissed = "Search param OrderId is required";
  }

  return {
    params: {
      orderId,
    },
    isParamsValid: isOrderIdValid,
    errors,
  };
};
