import React, { useContext } from "react";
import { AppContext } from "../../../../context/app-context";
import { AppMode } from "../../card-form/card-form";
import { useTranslation } from "react-i18next";

export interface CardFooterProps {}

const CardFooter: React.FC<CardFooterProps> = () => {
  const { t } = useTranslation();
  const link = process.env.REACT_APP_REGULATIONS_LINK;
  const { mode } = useContext(AppContext);

  return (
    <div className="card__footer">
      {mode === AppMode.TRANSACTION ? (
        <>
          {t("Dokonując płatności akceptujesz")}{" "}
          <a href={link} target="_blank">
            {t("regulamin BenefitSystems")}
          </a>
          .
        </>
      ) : (
        <>
          {t("Zarządzanie płatnościami wiążę się z akceptacją")}{" "}
          <a
            href={link}
            target="_blank"
            data-testid="cardFooterRegulationsLink"
          >
            {t("regulaminu BenefitSystems")}
          </a>
          .
        </>
      )}
    </div>
  );
};

export default CardFooter;
