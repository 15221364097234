import FinalizeOrderView from "../../../views/finalize-order/finalize-order";
import React, { useMemo } from "react";
import StatusCard, { StatusCardEnum } from "../../utils/status-card";
import { getOrderFromParams } from "../../../utils/get-order-from-params";
import { OrderParamsInfo } from "../../../views/finalize-order/finalize-order.types";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const FinalizeOrderParamsAnalyzer: React.FC = () => {
  const { t } = useTranslation();
  const [params] = useSearchParams();

  const { params: orderConfig, isParamsValid } = useMemo<OrderParamsInfo>(
    () => getOrderFromParams(params),
    [params]
  );

  if (!isParamsValid) {
    return (
      <StatusCard
        message={t("Podane parametry zamówienia są nieprawidłowe.")}
        type={StatusCardEnum.ERROR}
      />
    );
  }

  return <FinalizeOrderView orderConfig={orderConfig} />;
};

export default FinalizeOrderParamsAnalyzer;
