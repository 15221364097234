import Loading from "../../components/utils/loading";
import React, { useEffect, useState } from "react";
import StatusCard, { StatusCardEnum } from "../../components/utils/status-card";
import { AppMode } from "../../components/utils/card-form/card-form";
import { AuthOrderConfigParams } from "./finalize-recurring.types";
import { paymentApi } from "../../services/api/payments/payments.api";
import { useQuery } from "@tanstack/react-query";
import { useRequestPolling } from "../../hooks/useRequestPolling/useRequestPolling";
import { useTranslation } from "react-i18next";
import {
  ErrorResponse,
  RecurringChangeStatusDTO,
} from "../../services/api/payments/payments.types";

const MAX_RETRY_TO_GET_ORDER_STATUS =
  Number(process.env.REACT_APP_MAX_RETRY_TO_GET_ORDER_STATUS) || 10;
const REFETCH_INTERVAL_TO_GET_ORDER_STATUS =
  Number(process.env.REACT_APP_REFETCH_INTERVAL_TO_GET_ORDER_STATUS) || 1000;

export interface FinalizeRecurringViewProps {
  orderConfig: AuthOrderConfigParams;
}

const FinalizeRecurringView: React.FC<FinalizeRecurringViewProps> = ({
  orderConfig: { authOrderId },
}) => {
  const { t } = useTranslation();
  const [isCustomError, setIsCustomError] = useState<boolean>(false);

  const {
    isError: isResponseError,
    refetch,
    data,
  } = useQuery<RecurringChangeStatusDTO, ErrorResponse>({
    retry: 1,
    enabled: false,
    queryKey: ["getAuthOrderStatus"],
    queryFn: () => paymentApi.getRecurringStatus(authOrderId),
    initialData: {
      status: "PENDING",
    },
    onSuccess(data) {
      const { status } = data;
      if (status === "PENDING") {
        retry();
      }
    },
  });

  const { isMaxNumberOfRefetch, retry } = useRequestPolling(refetch, {
    MAX_RETRY: MAX_RETRY_TO_GET_ORDER_STATUS,
    REFETCH_INTERVAL: REFETCH_INTERVAL_TO_GET_ORDER_STATUS,
  });

  useEffect(() => {
    setIsCustomError(true);
  }, [isMaxNumberOfRefetch]);

  if (data?.status === "SUCCESS") {
    const messageContent: string[] = [
      t("Pomyślnie zaktualizowano metodę płatności."),
    ];
    const messageToShow: string = messageContent.join("<br />");
    return (
      <StatusCard
        message={messageToShow}
        type={StatusCardEnum.SUCCESS}
        withAutoFallback={true}
      />
    );
  }

  if (data?.status === "FAILED") {
    const messageContent: string[] = [
      t(
        "Nie mogliśmy przetworzyć Twojej płatności, upewnij się, że podane przez Ciebie dane są poprawne."
      ),
    ];
    const messageToShow: string = messageContent.join("<br />");
    return <StatusCard message={messageToShow} type={StatusCardEnum.ERROR} />;
  }

  if (isResponseError || isCustomError) {
    const messageContent: string[] = [
      t("Ups! Coś poszło nie tak po naszej stronie."),
      t("Odśwież stronę i spróbuj ponownie."),
    ];
    const messageToShow: string = messageContent.join("<br />");
    return <StatusCard message={messageToShow} type={StatusCardEnum.ERROR} />;
  }

  if (isMaxNumberOfRefetch) {
    const messageContent: string[] = [
      t(
        "Ups! Nie jesteśmy wstanie obecnie zweryfikować statusu Twojego zamówienia."
      ),
      t("Odśwież stronę i spróbuj ponownie."),
    ];
    const messageToShow: string = messageContent.join("<br />");
    return <StatusCard message={messageToShow} type={StatusCardEnum.ERROR} />;
  }

  if (!isMaxNumberOfRefetch && <Loading />) return <></>;
};

export default FinalizeRecurringView;
