import React from "react";
import { getTemplateSize } from "./main.helpers";
import { Header } from "../header";
import { useLocation } from "react-router-dom";
import "./main.css";
import "../card/card.css";

export type WrapperContext = "transaction" | "recurring";

export enum TemplateSizeEnum {
  BIG = "wrapper--big",
  SMALL = "wrapper--small",
}
interface MainProps {
  children?: React.ReactNode;
}

export const Main: React.FunctionComponent<MainProps> = ({ children }) => {
  const location = useLocation();

  const templateSize: TemplateSizeEnum = getTemplateSize(location);

  return (
    <div className="main">
      <Header />
      <div className={`wrapper ${templateSize}`}>{children}</div>
    </div>
  );
};

export default Main;
