import { AppMode } from "../card-form/card-form";
import { Location } from "react-router-dom";
import { TemplateSizeEnum } from ".";
import {
  FINALIZE_ORDER_PATH_SEGMENT,
  FINALIZE_RECURRING_PATH_SEGMENT,
  RECURRING_PATH_SEGMENT,
} from "../../../App";

export const getTemplateSize = (location: Location): TemplateSizeEnum => {
  switch (true) {
    // Order
    case location.pathname === "/":
      return TemplateSizeEnum.SMALL;
    // Recurring and errors, success, information and finalizers
    case location.pathname.includes(FINALIZE_RECURRING_PATH_SEGMENT):
    case location.pathname.includes(RECURRING_PATH_SEGMENT):
    case location.pathname.includes(FINALIZE_ORDER_PATH_SEGMENT):
    default:
      return TemplateSizeEnum.BIG;
  }
};

export const getAppMode = (): AppMode => {
  switch (true) {
    // Order
    case location.pathname === "/":
    case location.pathname.includes(FINALIZE_ORDER_PATH_SEGMENT):
      return AppMode.TRANSACTION;
    // Recurring
    case location.pathname.includes(RECURRING_PATH_SEGMENT):
    case location.pathname.includes(FINALIZE_RECURRING_PATH_SEGMENT):
    default:
      return AppMode.UPDATE_RECURRING;
  }
};
