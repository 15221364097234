import { Origin } from "../services/api/payments/payments.types";
import {
  PaymentConfigParams,
  PaymentConfigParamsInfo,
} from "../views/transactions/transactions.types";

export const getPaymentConfigFromParams = (
  params: URLSearchParams
): PaymentConfigParamsInfo => {
  const transactions: string[] = [
    ...params.getAll("transactions"),
    ...params.getAll("transactions[]"),
  ];
  const origin: Origin = params.get("origin");

  const paymentConfigParams: PaymentConfigParams = {
    transactions,
    origin,
  };

  const isOriginValid: boolean = origin?.length > 0;
  const isTransactionLengthValid: boolean = transactions.length > 0;
  const isParamsValid: boolean = isOriginValid && isTransactionLengthValid;

  let errors: Record<string, string> = {};

  if (!isOriginValid) {
    errors.origin = "Search param Origin is required";
  }

  if (!isTransactionLengthValid) {
    errors.transactions =
      "At least one search param Transaction on Transaction[] is required";
  }

  return {
    params: paymentConfigParams,
    isParamsValid,
    errors,
  };
};
