import cardLogo from "../../../assets/card.png";
import checkedIcon from "../../../assets/check.svg";
import Loading from "../loading";
import React, { useMemo, useState } from "react";
import ReactPaginate from "react-paginate";
import StatusCard, { StatusCardEnum } from "../status-card";
import { ArrowLeft, ArrowRight } from "@benefit-systems/common-components";
import { paymentApi } from "../../../services/api/payments/payments.api";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "../../../hooks/useWindowSize/useWindowSize";
import "./card-select.css";
import {
  CardInfo,
  ErrorResponse,
  PagedContent,
} from "../../../services/api/payments/payments.types";

const MIN_WINDOW_WIDTH = 500;
export interface CardSelectProps {
  onCardCheck: (employeeCardId: string) => Promise<void>;
  isLoading: boolean;
  currentCardId?: string;
}

const CardSelect: React.FC<CardSelectProps> = ({
  onCardCheck,
  isLoading,
  currentCardId,
}) => {
  const { t } = useTranslation();

  const [page, setPage] = useState<number>(1);
  const [selectedCardId, setSelectedCardId] = useState<string | undefined>(
    undefined
  );

  const { width } = useWindowSize();

  const onSubmit = async () => {
    if (selectedCardId) {
      await onCardCheck(selectedCardId);
    }
  };

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = e.target;
    setSelectedCardId(value);
  };

  const filters = useMemo<string | undefined>(() => {
    if (!currentCardId) {
      return undefined;
    }

    return `id!="${currentCardId}"`;
  }, [currentCardId]);

  const {
    data: originData,
    isError: isResponseError,
    isLoading: isCardsInfoLoading,
  } = useQuery<PagedContent<CardInfo>, ErrorResponse>({
    queryKey: ["employeeCards", page, filters],
    queryFn: () => paymentApi.getEmployeeCards({ page, filters }),
    keepPreviousData: true,
    staleTime: 5000,
  });

  const pageChange = (selectedItem: { selected: number }) => {
    const newPage: number = selectedItem.selected + 1;
    if (page !== newPage) {
      setPage(selectedItem.selected + 1);
    }
  };

  const data: PagedContent<CardInfo & { isCurrent: boolean }> = useMemo(
    () => ({
      ...originData,
      content: originData?.content.map((cardInfo) => ({
        ...cardInfo,
        isCurrent: cardInfo.id === currentCardId,
      })),
    }),
    [originData, currentCardId]
  );

  const isNextPage: boolean = data?.totalPages > 1;

  if (isCardsInfoLoading || !data) {
    return <Loading />;
  }

  if (isResponseError) {
    const messageContent: string[] = [
      t(
        "Ups! Pojawił się błąd podczas pobierania informacji o dostępnych metodach płatności"
      ),
      t("Odśwież stronę i spróbuj ponownie."),
    ];
    const messageToShow: string = messageContent.join("<br />");
    return <StatusCard message={messageToShow} type={StatusCardEnum.ERROR} />;
  }

  return (
    <div className="container card card-select__container">
      <p className="card-select__header">
        {t("Wybierz z listy dostępnych kart")}
      </p>
      <div className="container card card-select__rows-container">
        {data?.content?.length ? (
          data?.content?.map((card, index) => (
            <React.Fragment key={card.id}>
              <div
                className={`card-select__row ${
                  card.isCurrent ? "card-select__row--blocked" : ""
                }`}
                onClick={() => {
                  if (!card.isCurrent) {
                    setSelectedCardId(card.id);
                  }
                }}
              >
                <div className={`card-select__card mt-1 mb-1`}>
                  <input
                    type="radio"
                    name="card"
                    disabled={card.isCurrent}
                    className="card-select__input--radio"
                    checked={card.id === selectedCardId}
                    value={card.id}
                    onChange={onChange}
                  />
                  <div
                    className={`card-select__card ml-1 ${
                      width < MIN_WINDOW_WIDTH ? "card-select__card--small" : ""
                    }`}
                  >
                    <img
                      className="card__item__icon"
                      width="50"
                      height="31.5"
                      src={cardLogo}
                      alt="cards logo"
                    />
                    <div className="card-select__info">
                      <span>{card.cardNumber}</span>
                      <span>
                        {card.expirationMonth}/{card.expirationYear}
                      </span>
                    </div>
                  </div>
                </div>

                {card.id === selectedCardId && width > MIN_WINDOW_WIDTH && (
                  <div>
                    <img
                      className="card__item__icon"
                      width="18"
                      height="14"
                      src={checkedIcon}
                      alt="checked icon"
                    />
                  </div>
                )}
              </div>
              {index !== data.content?.length - 1 && (
                <hr className="card-select__separator" />
              )}
            </React.Fragment>
          ))
        ) : (
          <span className="card-select__no-card-message">
            {t("Brak dostępnych kart płatniczych")}
          </span>
        )}
        {isNextPage && (
          <ReactPaginate
            breakLabel="..."
            className="card-select__pagination-container"
            pageClassName="card-select__pagination-page"
            nextLinkClassName="card-select__pagination-arrow"
            previousLinkClassName="card-select__pagination-arrow"
            activeClassName="card-select__pagination-active"
            onPageChange={pageChange}
            pageCount={data.totalPages}
            renderOnZeroPageCount={null}
            nextLabel={
              <ArrowRight
                style={{
                  fill:
                    page < data.totalPages && data.totalPages !== 1
                      ? "#3642AE"
                      : "none",
                }}
              />
            }
            previousLabel={
              <ArrowLeft
                style={{
                  fill: page > 1 && data.totalPages !== 1 ? "#3642AE" : "none",
                }}
              />
            }
          />
        )}
        {data?.content?.length > 0 && (
          <div className="flex-container justify-end">
            <button
              data-testid="cardSelectChangeCardButton"
              onClick={onSubmit}
              disabled={isLoading || !selectedCardId}
              className={`card-select__button ${
                !selectedCardId ? "card-select__button--disabled" : ""
              }`}
            >
              {t("Wybierz")}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default CardSelect;
