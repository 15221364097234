import PayUWrapper from "../../components/utils/payu-wrapper/payu-wrapper";
import React, { useMemo, useState } from "react";
import { CurrentRecurring } from "../../components/recurring/current-recurring/current-recurring";
import { UpdateRecurring } from "../../components/recurring/update-recurring/update-recurring";

export enum Steps {
  CURRENT_RECURRING,
  UPDATE_RECURRING,
}

export const RecurringView = () => {
  const [step, setStep] = useState<Steps>(Steps.CURRENT_RECURRING);
  const section = useMemo(() => {
    switch (step) {
      case Steps.CURRENT_RECURRING:
        return (
          <CurrentRecurring nextPage={() => setStep(Steps.UPDATE_RECURRING)} />
        );
      case Steps.UPDATE_RECURRING:
        return (
          <UpdateRecurring backPage={() => setStep(Steps.CURRENT_RECURRING)} />
        );
    }
  }, [step, setStep]);

  return (
    <>
      <PayUWrapper>{section}</PayUWrapper>
    </>
  );
};
