import { useEffect, useState } from "react";

export const useWindowSize = () => {
  const [size, setSize] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    const getWindowSize = () => {
      const { innerHeight, innerWidth } = window;
      setSize({
        width: innerWidth,
        height: innerHeight,
      });
    };

    // For start
    getWindowSize();
    window.addEventListener("resize", getWindowSize);

    return () => {
      window.removeEventListener("resize", getWindowSize);
    };
  }, []);

  return size;
};
