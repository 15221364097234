import errorIcon from "../../../assets/error.svg";
import infoIcon from "../../../assets/info.svg";
import React from "react";
import successIcon from "../../../assets/success.svg";
import { StatusCardEnum } from ".";

export const getStatusIcon = (type: StatusCardEnum): JSX.Element => {
  switch (type) {
    case StatusCardEnum.INFO:
      return <img width="28" height="28" src={infoIcon} alt="info icon" />;
    case StatusCardEnum.SUCCESS:
      return (
        <img width="28" height="28" src={successIcon} alt="success icon" />
      );
    case StatusCardEnum.ERROR:
      return <img width="28" height="28" src={errorIcon} alt="error icon" />;
    default:
      return <></>;
  }
};
