import Loading from "../../components/utils/loading";
import React, { useEffect } from "react";
import StatusCard, { StatusCardEnum } from "../../components/utils/status-card";
import { AppMode } from "../../components/utils/card-form/card-form";
import { OrderConfigParams } from "./finalize-order.types";
import { paymentApi } from "../../services/api/payments/payments.api";
import { useQuery } from "@tanstack/react-query";
import { useRequestPolling } from "../../hooks/useRequestPolling/useRequestPolling";
import { useTranslation } from "react-i18next";
import {
  ErrorResponse,
  OrderStatusResponse,
} from "../../services/api/payments/payments.types";

const MAX_RETRY_TO_GET_ORDER_STATUS =
  Number(process.env.REACT_APP_MAX_RETRY_TO_GET_ORDER_STATUS) || 10;
const REFETCH_INTERVAL_TO_GET_ORDER_STATUS =
  Number(process.env.REACT_APP_REFETCH_INTERVAL_TO_GET_ORDER_STATUS) || 1000;

export interface FinalizeOrderViewProps {
  orderConfig: OrderConfigParams;
}

const FinalizeOrderView: React.FC<FinalizeOrderViewProps> = ({
  orderConfig: { orderId },
}) => {
  const { t } = useTranslation();

  const {
    data,
    isError: isResponseError,
    refetch,
  } = useQuery<OrderStatusResponse, ErrorResponse>({
    retry: 1,
    enabled: false,
    queryKey: ["getOrderStatus"],
    queryFn: () => paymentApi.getOrderStatus(orderId),
    initialData: {
      status: "PENDING",
    },
    onSuccess(data) {
      const { status } = data;
      if (status === "PENDING") {
        retry();
      } else if (status === "SUCCESS") {
        location.href = data.redirectUriSuccess;
      } else {
        location.href = data.redirectUriFailure;
      }
    },
  });

  const { isMaxNumberOfRefetch, retry } = useRequestPolling(refetch, {
    MAX_RETRY: MAX_RETRY_TO_GET_ORDER_STATUS,
    REFETCH_INTERVAL: REFETCH_INTERVAL_TO_GET_ORDER_STATUS,
  });

  useEffect(() => {
    if (isMaxNumberOfRefetch) {
      tryToLoadErrorUrl();
    }
  }, [isMaxNumberOfRefetch]);

  const tryToLoadErrorUrl = () => {
    if (data?.redirectUriFailure) {
      location.href = data.redirectUriFailure;
    }
  };

  if (isResponseError) {
    const messageContent: string[] = [
      t("Ups! Coś poszło nie tak po naszej stronie."),
      t("Odśwież stronę i spróbuj ponownie."),
    ];
    const messageToShow: string = messageContent.join("<br />");
    return <StatusCard message={messageToShow} type={StatusCardEnum.ERROR} />;
  }

  if (isMaxNumberOfRefetch) {
    const messageContent: string[] = [
      t(
        "Ups! Nie jesteśmy wstanie obecnie zweryfikować statusu Twojego zamówienia."
      ),
      t("Odśwież stronę i spróbuj ponownie."),
    ];
    const messageToShow: string = messageContent.join("<br />");
    return <StatusCard message={messageToShow} type={StatusCardEnum.ERROR} />;
  }

  return <>{!isMaxNumberOfRefetch && <Loading />}</>;
};

export default FinalizeOrderView;
