import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { AppContext } from "../../../context/app-context";
import { TFunction, useTranslation } from "react-i18next";
import "./card-form.css";

const getOptionsForms = (
  t: TFunction<"translation", undefined>
): payu.SecureFormOptions => ({
  placeholder: {
    number: t("Numer karty"),
    date: t("MM/RR"),
    cvv: t("CVV"),
  },
  style: {
    basic: {
      fontSize: "18px",
    },
  },
  lang: "pl",
});

const tokenType: payu.tokenType = "MULTI";

export enum AppMode {
  TRANSACTION,
  UPDATE_RECURRING,
}
export interface CardFormProps {
  totalAmount?: number;
  onFinalize: (payuToken: string) => Promise<void>;
}

const CardForm: React.FC<CardFormProps> = ({ totalAmount, onFinalize }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<payu.SecureFormErrorMessage[]>([]);

  const { mode } = useContext(AppContext);

  const errorInfo = useMemo(() => {
    const cardErrors = errors.filter((error) => error.code.includes("card"));
    const expDateErrors = errors.filter((error) =>
      error.code.includes("expDate")
    );
    const cvvErrors = errors.filter((error) => error.code.includes("cvv"));

    return {
      card: cardErrors,
      expData: expDateErrors,
      cvv: cvvErrors,
    };
  }, [errors]);

  const payuSdkForms: payu.PayU = useMemo(() => {
    const posId =
      mode === AppMode.TRANSACTION
        ? process.env.REACT_APP_PAYU_TRANSACTION_POSID
        : process.env.REACT_APP_PAYU_RECURRING_POSID;
    return PayU(posId);
  }, [mode]);

  const secureForms: payu.SecureForms = useMemo(
    () => payuSdkForms.secureForms(),
    [payuSdkForms]
  );

  const payuCardNumberContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const isContainerEmpty: boolean = !Boolean(
      payuCardNumberContainerRef.current.innerHTML
    );
    if (isContainerEmpty) {
      const optionsForms = getOptionsForms(t);
      const cardNumber = secureForms.add("number", optionsForms);
      const cardDate = secureForms.add("date", optionsForms);
      const cardCvv = secureForms.add("cvv", optionsForms);

      cardNumber.render("#payu-card-number");
      cardDate.render("#payu-card-date");
      cardCvv.render("#payu-card-cvv");
    }
  }, [secureForms]);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    setLoading(true);

    sendForm();
  };

  const sendForm = async (): Promise<void> => {
    try {
      const result = await payuSdkForms.tokenize(tokenType);

      if (result.status === "SUCCESS") {
        const { token: payuToken } = result.body;
        await onFinalize(payuToken);
      } else {
        setErrors(result.error.messages);
      }
    } catch (e) {
      // TODO: add an error logging mechanism
    } finally {
      setLoading(false);
    }
  };

  return (
    <form className="card-form" onSubmit={handleSubmit}>
      <div className="card-form__element" data-testid="cardFormCardContainer">
        <label className="card-form__label">{t("Numer karty")}</label>
        <div
          ref={payuCardNumberContainerRef}
          className="card-form__input"
          id="payu-card-number"
        ></div>
        {!!errorInfo.card.length && <span>{t(errorInfo.card[0].message)}</span>}
      </div>
      <div className="card-form__details">
        <div
          className="card-form__element card-form__element--details expiration"
          data-testid="cardFormExpDateContainer"
        >
          <label className="card-form__label">{t("Data ważności")}</label>
          <div className="card-form__input" id="payu-card-date"></div>
          {!!errorInfo.expData.length && (
            <span>{t(errorInfo.expData[0].message)}</span>
          )}
        </div>
        <div
          className="card-form__element card-form__element--details cvv"
          data-testid="cardFormCVVContainer"
        >
          <label className="card-form__label">{t("CVV")}</label>
          <div className="card-form__input" id="payu-card-cvv"></div>
          {!!errorInfo.cvv.length && <span>{t(errorInfo.cvv[0].message)}</span>}
        </div>
      </div>

      <div className="flex-container justify-end">
        <button
          data-testid="cardFormSubmitButton"
          type="submit"
          disabled={loading}
          className="card-form__submit"
          id="tokenizeButton"
        >
          {mode === AppMode.TRANSACTION
            ? `${t("Zapłać")} ${totalAmount} ${t("zł")}`
            : `${t("Zapisz")}`}
        </button>
      </div>
    </form>
  );
};

export default CardForm;
