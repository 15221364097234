import CardSection, { PayPayload } from "../../utils/card-section/card-section";
import PaymentDetails from "../payment-details/payment-details";
import React from "react";
import { PaymentMethod } from "../../../services/api/payments/payments.types";
import { TransactionsSummary } from "../../../utils/get-transactions-summary";

export interface CyclicPaymentsProps {
  transactionsSummary: TransactionsSummary;
  isLoading: boolean;
  onPay: (payload: PayPayload) => Promise<void>;
  setSelectedPaymentMethod?: React.Dispatch<
    React.SetStateAction<PaymentMethod>
  >;
}

const CyclicPayments: React.FC<CyclicPaymentsProps> = ({
  transactionsSummary,
  isLoading,
  onPay,
  setSelectedPaymentMethod,
}) => {
  return (
    <>
      <PaymentDetails transactionsSummary={transactionsSummary} />
      <CardSection
        isLoading={isLoading}
        onPay={onPay}
        totalAmount={transactionsSummary.totalAmount}
        setSelectedPaymentMethod={setSelectedPaymentMethod}
      />
    </>
  );
};

export default CyclicPayments;
