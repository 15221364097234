import i18next from "i18next";
import React from "react";
import {
  defaultLanguage,
  i18nextChangeLanguage,
  Language,
} from "../../../bootstrap/i18next/i18next";
import { useTranslation } from "react-i18next";
import "./language-switcher.css";

const availableLanguages: Language[] = ["pl", "en"];

export const LanguageSwitcher: React.FunctionComponent = () => {
  const { t } = useTranslation();

  const language: Language = (i18next.language as Language) || defaultLanguage;
  const setLanguage = (language: Language) => {
    i18nextChangeLanguage(language);
  };

  return (
    <div className="language-switcher">
      <div className="language-switcher__label">{t("Wybierz język")}:</div>
      {availableLanguages.map((languageValue) => (
        <button
          key={languageValue}
          className={`language-switcher__button ${
            language === languageValue && "language-switcher__button--active"
          }`}
          onClick={() => setLanguage(languageValue)}
        >
          {languageValue.toUpperCase()}
        </button>
      ))}
    </div>
  );
};
