import FinalizeOrderParamsAnalyzer from "./components/analyzers/finalize-order-params-analyzer/finalize-order-params-analyzer";
import FinalizeRecurringParamsAnalyzer from "./components/analyzers/finalize-recurring-params-analyzer/finalize-recurring-params-analyzer";
import keycloak from "./bootstrap/keycloak/keycloak";
import LoadingWithoutTranslation from "./components/utils/loading-without-translation";
import Main from "./components/utils/main";
import NotFound from "./views/not-found";
import React, { useEffect, useMemo, useState } from "react";
import TransactionsParamsAnalyzer from "./components/analyzers/transactions-params-analyzer/transactions-params-analyzer";
import { AppContextProvider } from "./context/app-context";
import { AppNotificationContextProvider } from "./context";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ErrorBoundary } from "./components/utils/error-boundary";
import { Notifier } from "./components/utils/notifier";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { RecurringView } from "./views/recurring/recurring";
import { useKeycloak } from "@react-keycloak/web";
import {
  getLanguageFromToken,
  i18nextChangeLanguage,
  initI18n,
  Language,
} from "./bootstrap/i18next/i18next";

export const RECURRING_PATH_SEGMENT = "recurrings" as const;
export const FINALIZE_RECURRING_PATH_SEGMENT = "finalize-recurring" as const;
export const FINALIZE_ORDER_PATH_SEGMENT = "finalize-order" as const;

const queryClient = new QueryClient();

export const App = () => {
  const { initialized } = useKeycloak();
  const [isI18NextInitialized, setIsI18NextInitialized] =
    useState<boolean>(false);

  const isAppReady = useMemo<boolean>(
    () => initialized && isI18NextInitialized,
    [initialized, isI18NextInitialized]
  );

  useEffect(() => {
    (async () => {
      if (initialized) {
        await initI18n();
        const { token } = keycloak;
        const language: Language = getLanguageFromToken(token);
        await i18nextChangeLanguage(language);
        setIsI18NextInitialized(true);
      }
    })();
  }, [initialized]);

  return (
    <ErrorBoundary>
      <AppContextProvider>
        <AppNotificationContextProvider>
          <QueryClientProvider client={queryClient}>
            <BrowserRouter>
              {isAppReady ? (
                <Main>
                  <Routes>
                    {/* Order */}
                    <Route index element={<TransactionsParamsAnalyzer />} />
                    <Route
                      path={FINALIZE_ORDER_PATH_SEGMENT}
                      element={<FinalizeOrderParamsAnalyzer />}
                    />
                    {/* Recurring */}
                    <Route
                      path={`${RECURRING_PATH_SEGMENT}/:recurringId`}
                      element={<RecurringView />}
                    />
                    <Route
                      path={FINALIZE_RECURRING_PATH_SEGMENT}
                      element={<FinalizeRecurringParamsAnalyzer />}
                    />
                    {/* Else */}
                    <Route path={"*"} element={<NotFound />} />
                  </Routes>
                </Main>
              ) : (
                <LoadingWithoutTranslation />
              )}
            </BrowserRouter>
          </QueryClientProvider>
          <Notifier />
        </AppNotificationContextProvider>
      </AppContextProvider>
    </ErrorBoundary>
  );
};
