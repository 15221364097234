import FinalizeRecurringView from "../../../views/finalize-recurring/finalize-recurring";
import React, { useMemo } from "react";
import StatusCard, { StatusCardEnum } from "../../utils/status-card";
import { AppMode } from "../../utils/card-form/card-form";
import { AuthOrderParamsInfo } from "../../../views/finalize-recurring/finalize-recurring.types";
import { getAuthOrderFromParams } from "../../../utils/get-auth-order-from-params";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const FinalizeRecurringParamsAnalyzer: React.FC = () => {
  const { t } = useTranslation();
  const [params] = useSearchParams();

  const { params: authOrderConfig, isParamsValid } =
    useMemo<AuthOrderParamsInfo>(
      () => getAuthOrderFromParams(params),
      [params]
    );

  if (!isParamsValid) {
    return (
      <StatusCard
        message={t("Podane parametry są nieprawidłowe.")}
        type={StatusCardEnum.ERROR}
      />
    );
  }

  return <FinalizeRecurringView orderConfig={authOrderConfig} />;
};

export default FinalizeRecurringParamsAnalyzer;
