import CardFooter from "../../utils/card/card-footer";
import CardForm, { AppMode } from "../../utils/card-form/card-form";
import CardSelect from "../../utils/card-select/card-select";
import cardsLogo from "../../../assets/cards.png";
import cardsLogoBig from "../../../assets/cards@2x.png";
import infoIcon from "../../../assets/info.svg";
import lockIcon from "../../../assets/lock-closed.svg";
import React, { useState } from "react";
import { ArrowLeft } from "@benefit-systems/common-components";
import { AxiosResponse } from "axios";
import { paymentApi } from "../../../services/api/payments/payments.api";
import { useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./update-recurring.css";
import StatusCard, {
  FALLBACK_URI,
  REDIRECT_URI,
  StatusCardEnum,
} from "../../utils/status-card";
import {
  QueryClient,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import {
  RecurringChangeResponse,
  RecurringInfoDTO,
  RecurringPayByCardFromSourcePayload,
  RecurringPayByNewCardPayload,
  RecurringPayload,
} from "../../../services/api/payments/payments.types";

export interface UpdateRecurringProps {
  backPage: () => void;
}

export const UpdateRecurring: React.FC<UpdateRecurringProps> = ({
  backPage,
}) => {
  const { t } = useTranslation();

  const { recurringId } = useParams();
  const [params] = useSearchParams();
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const client: QueryClient = useQueryClient();

  const redirectUri: string | undefined =
    params.get(FALLBACK_URI) || params.get(REDIRECT_URI);

  const fallback: string =
    redirectUri || process.env.REACT_APP_RESERVE_FALLBACK_LINK;

  const recurringInfo: RecurringInfoDTO = client.getQueryData(["getRecurring"]);

  const { mutate, isLoading, isError } = useMutation<
    AxiosResponse<RecurringChangeResponse, any>,
    void,
    RecurringPayload
  >({
    mutationFn: (payload) => paymentApi.patchRecurring(recurringId, payload),
    onSuccess: (response) => {
      const { data } = response;
      // Redirect tu payu
      if (response.data?.redirectUri) {
        location.href = data.redirectUri;
        return;
      }

      setIsSuccess(true);
    },
  });

  const currentCardId: string | undefined =
    recurringInfo &&
    "employeeCard" in recurringInfo &&
    recurringInfo.employeeCard &&
    recurringInfo.employeeCard.id;

  const onCardChange = async (payuToken: string) => {
    const payload: RecurringPayByNewCardPayload = {
      paymentMethod: "PAY_BY_CARD",
      token: payuToken,
    };

    if (redirectUri) {
      payload.redirectUri = redirectUri;
    }

    mutate(payload);
  };

  const onCardCheck = async (employeeCardId: string) => {
    const payload: RecurringPayByCardFromSourcePayload = {
      paymentMethod: "PAY_BY_CARD",
      employeeCardId,
    };

    if (redirectUri) {
      payload.redirectUri = redirectUri;
    }

    mutate(payload);
  };

  if (isError) {
    const messageContent: string[] = [
      t("Ups! Coś poszło nie tak."),
      t("Spróbuj ponownie później."),
    ];
    const messageToShow: string = messageContent.join("<br />");
    return <StatusCard message={messageToShow} type={StatusCardEnum.ERROR} />;
  }

  if (isSuccess) {
    const messageContent: string[] = [
      t("Pomyślnie zaktualizowano metodę płatności"),
    ];
    const messageToShow: string = messageContent.join("<br />");
    return (
      <StatusCard
        message={messageToShow}
        type={StatusCardEnum.SUCCESS}
        withAutoFallback={true}
      />
    );
  }

  return (
    <section className="container card update-recurring">
      <button
        className="update-recurring__back-button"
        data-testid="updateRecurringBackButton"
        onClick={() => {
          location.href = fallback;
        }}
      >
        <ArrowLeft width={10} height={10} /> {t("Wróć")}
      </button>
      <button
        data-testid="updateRecurringBackToPrevViewButton"
        className="card-back-button update-recurring__header"
        onClick={backPage}
      >
        <ArrowLeft width={12} height={12} /> {t("Zmiana metody płatności")}
      </button>
      <div className="update-recurring__info">
        <img
          className="update-recurring__info__icon"
          src={infoIcon}
          alt="info icon"
        />
        {t(
          "Wybrana metoda jest domyślną podczas kolejnego okresu rozliczeniowego"
        )}
      </div>

      <span>{t("Wprowadź nową kartę")}:</span>
      <div className="update-recurring__method">
        <div className="update-recurring__payment-info">
          <img
            className="card__item__icon"
            width="83"
            height="35"
            srcSet={`${cardsLogoBig} 2x`}
            src={cardsLogo}
            alt="cards logo"
          />
          <div className="card__item__text">
            <h2 className="card__item__title">
              {t("Płatność cykliczna kartą")}
              <img width="18" height="18" src={lockIcon} />
            </h2>
            <span className="card__item__desc">
              {t("Każdego miesiąca będziemy potrącali opłatę z Twojej karty")}
            </span>
          </div>
        </div>
        <hr className="update-recurring__separator" />
        <CardSelect
          onCardCheck={onCardCheck}
          isLoading={isLoading}
          currentCardId={currentCardId}
        />
        <p className="update-recurring__text">
          {t("lub wprowadź nową kartę")}:
        </p>
        <CardForm onFinalize={onCardChange} />
      </div>
      <CardFooter />
    </section>
  );
};
